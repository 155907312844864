import React from "react"
import styled from "styled-components"
import Layout from "./layout"
import DropdownMenu from "../common/DropdownMenu"
import SubDropdownMenu from "../common/SubDropdownMenu"
import DropdownLink from "../common/DropdownLink"
import EmailSignup from "../email/EmailSignup"
import BoxedContainer from "../common/BoxedContainer"

function UserGuide(props) {
  const { children } = props

  return (
    <Layout>
      <div className="container boxed-width padding-top-medium padding-bottom-small">
        <NavigationColumn className="col-3 col-3-d">
          {/* Fundamentals */}
          <DropdownMenu menuTitle="Fundamentals">
            <DropdownLink to="/user-guide/introduction">
              Introduction
            </DropdownLink>
            <SubDropdownMenu menuTitle="Typography">
              <DropdownLink to="/user-guide/fundamentals/typography/typography-introduction">
                Typography introduction
              </DropdownLink>
            </SubDropdownMenu>
            <DropdownLink to="/user-guide/colour">Colour</DropdownLink>
            <DropdownLink to="/user-guide/grids">Grids</DropdownLink>
            <DropdownLink to="/user-guide/layout">Layout</DropdownLink>
            <DropdownLink to="/user-guide/spacing">Spacing</DropdownLink>
          </DropdownMenu>
          {/* Atom Design */}
          <DropdownMenu menuTitle="Atom design">
            <DropdownLink to="/user-guide/">Introduction</DropdownLink>
            <DropdownLink to="/user-guide/">Typography</DropdownLink>
            <DropdownLink to="/user-guide/">Colour</DropdownLink>
            <DropdownLink to="/user-guide/">Grids</DropdownLink>
            <DropdownLink to="/user-guide/">Layout</DropdownLink>
            <DropdownLink to="/user-guide/">Spacing</DropdownLink>
          </DropdownMenu>
        </NavigationColumn>
        <ContentColumn className="col-8">{children}</ContentColumn>
      </div>
      <BoxedContainer>
        <EmailSignup />
      </BoxedContainer>
    </Layout>
  )
}

export default UserGuide

const NavigationColumn = styled.aside``

const ContentColumn = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin-bottom: 40px;
  }
`
