import React from "react"
import styled from "styled-components"
import caret from "../../images/caret.svg"

DropdownMenu.defaultProps = {
  menuTitle: "Dropdown title",
}

function DropdownMenu(props) {
  const { menuTitle, children } = props
  const [menuOpen, setMenuOpen] = React.useState(true)

  return (
    <div>
      <StyledDropdownHeader
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <span className="noselect">{menuTitle}</span>
        <StyledCaret src={caret} menuOpen={menuOpen} className="noselect" />
      </StyledDropdownHeader>
      {menuOpen && <StyledUl>{children}</StyledUl>}
    </div>
  )
}

export default DropdownMenu

const StyledDropdownHeader = styled.h5`
  color: ${props => props.theme.colors.white};
  display: flex;
  align-self: flex-start;
  transition: ${props => props.theme.transitions.all};
  align-items: center;
  cursor: pointer;
  span {
    pointer-events: none;
  }
`

const StyledCaret = styled.img`
  margin-left: auto;
  transition: ${props => props.theme.transitions.all};
  transform: ${props => (props.menuOpen ? "none" : "rotate(-90deg)")};
`

const StyledUl = styled.ul`
  list-style-type: none;
  margin-bottom: 20px;
`
