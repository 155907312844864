import React from "react"
import styled from "styled-components"
import caret from "../../images/caret.svg"

SubDropdownMenu.defaultProps = {
  menuTitle: "Dropdown title",
}

function SubDropdownMenu(props) {
  const { menuTitle, children } = props
  const [menuOpen, setMenuOpen] = React.useState(false)
  const str = menuTitle.replace(/\s/g, "-")
  const id = str.toLowerCase()

  React.useEffect(() => {
    if (document.querySelectorAll(`#${id} .active-page`)[0]) {
      console.log("fucking what")
      setMenuOpen(true)
    }
  }, [])

  return (
    <StyledLi id={id}>
      <StyledDropdownHeader
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <span className="noselect">{menuTitle}</span>
        <StyledCaret src={caret} menuOpen={menuOpen} className="noselect" />
      </StyledDropdownHeader>
      <StyledUl menuOpen={menuOpen}>{children}</StyledUl>
    </StyledLi>
  )
}

export default SubDropdownMenu

const StyledLi = styled.li`
  display: block;
`

const StyledDropdownHeader = styled.p`
  color: ${props => props.theme.colors.white};
  display: flex;
  align-self: flex-start;
  transition: ${props => props.theme.transitions.all};
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 8px 0px 8px 20px;
  border-radius: 8px;
  margin-bottom: 0;

  span {
    pointer-events: none;
  }

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.white};
  }
  &.active {
    background-color: ${props => props.theme.colors.lightbackground};
  }
`

const StyledCaret = styled.img`
  margin-left: auto;
  transition: ${props => props.theme.transitions.all};
  transform: ${props => (props.menuOpen ? "none" : "rotate(-90deg)")};
`

const StyledUl = styled.ul`
  list-style-type: none;
  display: ${props => (props.menuOpen ? "block" : "none")};
`
