import React from "react"
import UserGuide from "../../components/template-components/UserGuide"

const IndexPage = () => (
  <UserGuide>
    <h1>Introduction</h1>
    <p>
      Hello and welcome to the design handbook. As much as this is called the
      design handbook, it's more like a user guide for learning user interface
      and user experience design. The aim of this website is to create the
      learning platform that I wish I had for learning design.
    </p>
    <p>
      The current world of design learning is fractured and inconsistent in my
      experience. Not only do we have hundreds of thousands teaching the
      subject, but we also have varying levels of quality too. The content you
      find here is the work of hundreds of hours to make learning design easier.
    </p>
    <p>
      In the website you are going to find a mixture of content for learning
      design, with written guides, and video tutorials completely unique to the
      design handbook.
    </p>
    <p>In the design handbook you will find:</p>
    <ul>
      <li>
        <strong>Fundamental design training</strong>
      </li>
      <li>
        <strong>
          Atoms, molecules, organisms, sections, and template design tutorials
          and best practice
        </strong>
      </li>
      <li>
        <strong>Wireframes for all of the above</strong>
      </li>
      <li>
        <strong>Organism design</strong>
      </li>
      <li>
        <strong>A slack group for design discussion with other members</strong>
      </li>
      <li>
        <strong>Any other resources that are also added to the website</strong>
      </li>
    </ul>
    <p>
      This site will slowly evolve and grow with content over time, so please if
      there is anything you want to see covered, or if you think something could
      be better, join the community, and share your thoughts.
    </p>
  </UserGuide>
)

export default IndexPage
