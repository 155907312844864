import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

DropdownLink.defaultProps = {
  linkTitle: "Call to action",
  to: "#",
}

function DropdownLink(props) {
  const { children, to } = props

  return (
    <StyledLi>
      <StyledLink to={to} activeClassName={"active-page"}>
        <span className="noselect">{children}</span>
      </StyledLink>
    </StyledLi>
  )
}

export default DropdownLink

const StyledLi = styled.li`
  display: inline-flex;
  width: 100%;
`

const StyledLink = styled(Link)`
  width: 100%;
  padding: 2px 20px;
  border-radius: 8px;
  margin-bottom: 0;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.white};
  }
  &.active-page {
    background-color: ${props => props.theme.colors.lightbackground};
  }

  span {
    pointer-events: none;
  }
`
